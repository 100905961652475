
import React from 'react';
import TextField from '@mui/material/TextField';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import {addDays, format} from 'date-fns'

import {styled} from "@mui/material";
import { Button } from '@mui/material'

import {queryApiGet} from "../api";

const CssTextField = styled(TextField)({
    'input': {
        color: '#FFFFFF'
    },
    'textarea': {
        color: '#FFFFFF'
    },
    '& label': {
        color: '#FFFFFF'
    },

    '& .MuiInput-underline:after': {
        borderBottomColor: '#B2BAC2',
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            borderColor: '#197cd2',
        },
        '&:hover fieldset': {
            borderColor: '#197cd2',
        },

    },
});

export default class ExperimentDescriptionTemplateGenerator extends React.Component {
    constructor(props) {
        super(props);

        this.props = props

        this.siteId = this.props.computedMatch.params.siteId;
        this.variations =  this.props.computedMatch.params.variations;
        this.experimentId = this.props.computedMatch.params.experimentId;

        this.state = {
            description: '',
            banner: true,
            video: true,
            mobile: true,
            desktop: true,
            cardText: '',
            startDate: format(new Date(), 'yyyy-MM-dd'),
            endDate: format(addDays(new Date(), 1), 'yyyy-MM-dd')
        }
    }

    componentDidMount() {
        queryApiGet(`/api/getSiteDomain?siteId=${this.siteId}`)
            .then(res => this.setState({siteDomain: res.siteDomain, ready: true}))
            .catch(e => {
                console.error(e)
                this.setState({ready: true})
            })
    }

    generateExperimentCard() {
        const siteDomain = this.state.siteDomain || 'Site domain is missing, contact IT team'

        const cardText = `Сайт:  ${siteDomain} (${this.siteId})\nДаты:  ${this.state.startDate} - ${this.state.endDate}\nСтатус:  Запущен :large_green_square:\nТип: ${this.getEnabledABTestTypeStr()}\nПлатформы: ${this.getEnabledPlatformsStr()}\nВариации: ${this.variations}\nОписание: ${this.state.description}\nid: ${this.getDeployerUrlWithExperimentId()}\n${this.generateRedashUrl(this.state.video, this.state.banner)}`

        return cardText
    }

    getDeployerUrlWithExperimentId() {
        return `https://deploy.notsy.io/updateAb/${this.siteId}/${this.experimentId}`
    }

    generateRedashUrl(includeVideo, includeBanner) {
        if (includeBanner && !includeVideo) {
            return this.generateRedashBannerUrl()
        }

        if (includeVideo && !includeBanner) {
            return this.generateRedashVideoUrl()
        }

        if (includeBanner && includeVideo) {
            //eslint-disable-next-line
            return `${this.generateRedashBannerUrl(true)}\n${this.generateRedashVideoUrl(true)}`
        }
    }

    generateRedashBannerUrl(showType) {
        const devicesStr = this.getDevicesStr()
        const dateStr = this.getDatesStr()
        const variationsStr = this.getVariationsStr()
        const siteId = this.siteId.toUpperCase()

        return `redash${showType? '(banner)': ''}: https://redash.notsy.io/dashboards/7-ab-results?${devicesStr}&p_Time%20-%20range=${dateStr}&p_Variation%2${variationsStr}&p_ad_unit_=%5B%22All%22%5D&p_exclude%20ad%20unit=%5B%22Null%22%5D&p_site%20name=${siteId}`
    }
    generateRedashVideoUrl(showType) {
        const dateStr = this.getDatesStr()
        const devicesStr = this.getDevicesStr(true)
        const siteId = this.siteId.toUpperCase()
        const variationsStr = this.getVariationsStr()

        return `redash${showType ? '(video)' : ''}: https://redash.notsy.io/dashboards/26-video-ab-results-advertising-metrics-only-?p_Date=${dateStr}&${devicesStr}&p_Site%20code=${siteId}&p_Variation%2${variationsStr}`
    }

    getVariationsStr() {
        const variations = this.variations.split(',')
        return `01=${variations[0]}&p_Variation%202=${variations[1]}`
    }

    getDevicesStr(isVideo) {
        if (this.state.desktop && this.state.mobile) {
            return `p_Device%20category=%5B%22Smartphone%22%2C%22Desktop%22%5D`
        }

        if (this.state.desktop) {
            return `p_Device${isVideo ? '%20category' : ''}=%5B%22Desktop%22%5D`
        }

        if (this.state.mobile) {
            return `p_Device${isVideo ? '%20category' : ''}=%5B%22Smartphone%22%5D`
        }
    }

    getDatesStr() {
        return `${this.state.startDate}--${this.state.endDate}`
    }

    getEnabledPlatformsStr() {
        let resultArr = []

        if (this.state.mobile) {
            resultArr.push('mobile')
        }

        if (this.state.desktop) {
            resultArr.push('desktop')
        }


        return resultArr.join(', ')
    }
    getEnabledABTestTypeStr() {
        const resultArr = []

        if (this.state.banner) {
            resultArr.push('banner')
        }

        if (this.state.video) {
            resultArr.push('video')
        }

        console.log(resultArr )


        return resultArr.join(', ')
    }

    getTextField(text) {
        return (
            <CssTextField
            value={text}
            style={{width: '90%', paddingTop: '40px'}}
            multiline
            rows={16}
        />)
    }


    getCopyToClipboardButton() {
        const handleClick = () => navigator.clipboard.writeText(this.cardText)

        return <Button onClick={handleClick} style={{cursor: 'pointer'}}>Copy to clipboard</Button>
    }
    render() {
        this.cardText = this.state.ready ? this.generateExperimentCard() : 'Loading...'

        return (
            <div>
                <div style={{display: 'flex', flexDirection: 'column'}}>
                    <span style={{fontSize: '1.5em', color: '#4BB543'}}>AB test have been launched successfully!!!</span>
                    <span style={{marginTop: '10px'}}>Don't forget to post to slack about it. You can generate the message text using the form below</span>
                </div>

                <div style={{border: '1px white solid', minWidth: '80vw', minHeight: '80vh', marginTop: '20px', display: 'flex'}}>
                    <div style={{border: '1px white solid', flex: '1', padding: '5px', display: 'flex', flexDirection: 'column', justifyContent: 'center'}}>
                        <div>
                            <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                               <div>
                                   Site id: {this.siteId}
                               </div>

                                <div>
                                    Experiment id: {this.experimentId}
                                </div>
                            </div>
                        </div>

                        <div style={{paddingTop: '40px'}}>
                            <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                                <span style={{width: '100px'}}>Type: </span>
                                <FormGroup style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '250px'}}>
                                    <FormControlLabel control={<Switch checked={this.state.video} onChange={
                                        () => this.setState({ video: !this.state.video})}
                                    />} label="Video" />
                                    <FormControlLabel control={<Switch checked={this.state.banner} onChange={
                                        () => this.setState({ banner: !this.state.banner})}
                                    />} label="Banner" />
                                </FormGroup>
                            </div>
                        </div>

                        <div style={{paddingTop: '15px'}}>
                            <div style={{display: 'flex', justifyContent: 'space-evenly'}}>
                                <span style={{width: '100px'}}>Platform: </span>
                                <FormGroup style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '250px'}}>
                                    <FormControlLabel control={<Switch checked={this.state.mobile} onChange={
                                        () => this.setState({ mobile: !this.state.mobile })
                                    } />} label="Mobile" />
                                    <FormControlLabel control={<Switch checked={this.state.desktop} onChange={
                                        () => { this.setState({ desktop: !this.state.desktop})}
                                    } />} label="Desktop" />
                                </FormGroup>
                            </div>
                        </div>

                        <div style={{paddingTop: '40px', display: 'flex', justifyContent: 'center'}}>
                            <div style={{width: '80%', display: 'flex', justifyContent: 'space-between'}}>
                                <CssTextField
                                    label="Start date"
                                    value={this.state.startDate}
                                    onChange={(e) => {
                                        this.setState({startDate: e.target.value})
                                    }}
                                >

                                </CssTextField>
                                <CssTextField
                                    label="End date"
                                    value={this.state.endDate}
                                    onChange={(e) => {
                                        this.setState({endDate: e.target.value})
                                    }}
                                >

                                </CssTextField>
                            </div>
                        </div>

                        <div style={{paddingTop: '25px'}}>
                                <CssTextField
                                    style={{width: '80%'}}
                                    id="description"
                                    label="description"
                                    variant="outlined"
                                    onChange={(e) => {
                                        this.setState({description: e.target.value})
                                    }}
                                />
                        </div>
                    </div>

                    <div style={{border: '1px white solid', flex: 1, padding: '5px', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                        <div style={{width: '85%', display: "flex", justifyContent: 'flex-end',zIndex: 2, position: 'relative', top: '35px'}}>
                            {this.getCopyToClipboardButton()}
                        </div>

                        {this.getTextField(this.cardText)}
                    </div>
                </div>
            </div>

        )
    }
}
