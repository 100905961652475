
const BACKEND_DOMAIN = document.location.hostname === 'localhost' ? 'http://localhost:5000' : ''

class UnauthorizedQueryError extends Error {}

class RequestProcessingError extends Error {}

function queryApiGet(url) {
    return queryApi(url)
}

function queryApiPost(url, body) {
    return queryApi(url, {
        method: 'POST',
        body: JSON.stringify(body),
        headers: {"Content-Type": "application/json"}
    })
}

function queryApi(url, params = {}) {
    params = Object.assign(
        params,
        {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('token'),
                "Content-Type": "application/json"
            }
        }
    )

    return fetch(
        BACKEND_DOMAIN + url,
        params
    )
        .then(result => {
            if (result.ok) {
                return result.json()
            } else {
                if (result.status === 401) {
                    localStorage.removeItem('token')
                }

                return result.json().then(data => ({data, isError: true}))
            }
        })
        .catch(() => {
            return new Promise(res => res({data: {errorMessage: 'Server is down... Contact admins to fix it'}, isError: true}))
        })
        .then(patchedResponse => {
            if (patchedResponse.isError) {
                throw patchedResponse.data
            } else {
                return patchedResponse
            }
    })
}

export { queryApiGet, queryApiPost, RequestProcessingError, UnauthorizedQueryError }
