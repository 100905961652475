

import React from 'react';
import {withRouter, Redirect} from "react-router-dom";

import {DeployInterface} from './DeployInterface'
import {OPERATION_MODE_DEPLOY_TEST_VARIATION} from "./DeployInterface";

import {queryApiGet, queryApiPost, UnauthorizedQueryError, RequestProcessingError} from '../api.js'

import slugify from 'slugify'
import ErrorComponent from "./error";

import deployEventsTrackerService from "../services/deployEventsTrackerService";

class StartAb extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            redirectToAbDetailsInitiated: false,
            operationInProcess: false,
            deployComplete: false,
            errorOccurred: false,
            experimentId: "",
            testVariationSettingsBranch: 'master',
            testVariationLogicBranch: null,
            testVariationDeployed: false,
            testVariationInfo: null,
            settingsBranchesNamesLoaded: false,
            settingsBranchesNames: null,
            tagsNames: null,
            testVariationData: null,
            variationIds: null,
            buttonVersionLogicBranch: true,
            selectVersionLogicBranch: false,
            logicAndSettingsBranch: false
        };

        this.handleExperimentIdChange = this.handleExperimentIdChange.bind(this)
        this.handleTestVariationChoiceSubmit = this.handleTestVariationChoiceSubmit.bind(this)
        this.handleTestVariationDeployComplete = this.handleTestVariationDeployComplete.bind(this)
        this.handleExperimentFormSubmit = this.handleExperimentFormSubmit.bind(this)
        this.handleLogicBranchChoice = this.handleLogicBranchChoice.bind(this)
        this.processError = this.processError.bind(this)
    }

    componentDidMount() {
        this.loadBranchesNames()
        this.loadTagsNames();
        this.loadTestVariationData()
        this.loadVariationIds()
    }

    processError(e) {
        if (e instanceof UnauthorizedQueryError) {
            console.error('Unauthorized')
        } else if (e instanceof RequestProcessingError) {
            console.error(e.message)

            this.setState({
                errorOccurred: true
            })
        } else {
            throw e
        }
    }

    loadVariationIds() {
        queryApiGet(
            `/api/suggestABVariationIds?siteId=${this.props.match.params.siteId}`
        ).then((data) => {
            this.setState({
                variationIds: data.variationIds
            })
        }).catch((err) => this.setErrorState(err))
    }

    loadBranchesNames() {
        deployEventsTrackerService.trackSettingsBranchLoadingStarted();

        queryApiGet(
            '/api/settingsBranchNames'
        ).then(data => {
             deployEventsTrackerService.trackSettingsBranchLoadingFinished()

             this.setState({
                 settingsBranchesNamesLoaded: true,
                 settingsBranchesNames: data
             })
         }).catch((err) => {
             deployEventsTrackerService.trackSettingsBranchLoadingFailed(err)
             this.setErrorState(err)
        })
    }

    setErrorState(errorMessage) {
        this.setState({errorMessages: [...this.state.errorMessages || [], errorMessage]})
    }

    loadTagsNames() {
        deployEventsTrackerService.trackHigherVersionTagsLoadingStarted()

        queryApiGet(
            `/api/higherVersionTagsNames?siteId=${this.props.match.params.siteId}`
        ).then(data => {
            deployEventsTrackerService.trackHigherVersionTagsLoadingFinished()

            this.setState({
                tagsNames: data
            })
        }).catch((err) => {
            deployEventsTrackerService.trackHigherVersionTagsLoadingFailed(err)
            this.setErrorState(err)
        })
    }

    loadTestVariationData() {
        queryApiGet(
            `/api/siteStatus?siteId=${this.props.match.params.siteId}`
        ).then(data => {
                this.setState({
                    testVariationData: data,
                    testVariationLogicBranch: data.version.logic.commitish,
                    testVariationLogicHashBranch: data.version.logic.hash.substring(0, 6),
                    testVariationSettingsHashBranch: data.version.settings.hash.substring(0, 6)
                })
        }).catch((err) => this.setErrorState(err))
    }

    handleLogicBranchChoice() {
        this.setState({
            buttonVersionLogicBranch: false,
            selectVersionLogicBranch: true
        })
    }

    handleTestVariationDeployComplete(deployedVersionInfo) {
        this.setState({
            testVariationDeployed: true,
            testVariationInfo: deployedVersionInfo
        })
    }

    handleTestVariationChoiceSubmit() {
        const variationSettingsBranch = document.getElementById('settings_branch').value
        this.setState({
            testVariationSettingsBranch: variationSettingsBranch,
            logicAndSettingsBranch: true
        })

        if(document.getElementById('logic_branch')) {
            const variationLogicBranch = document.getElementById('logic_branch').value
            this.setState({
                testVariationLogicBranch: variationLogicBranch
            })
        }
    }

    handleExperimentIdChange(e) {
        this.setState({
            experimentId: slugify(e.target.value, {replacement: '_', lower: true})
        })
    }

    handleExperimentFormSubmit(e) {
        e.preventDefault()

        const d = new FormData(e.target)

        this.setState({
            operationInProcess: true
        })

        deployEventsTrackerService.trackAbStarted()

        queryApiPost(
             `/api/startAb?siteId=${this.props.match.params.siteId}&experimentId=${d.get('experiment_id')}&variationIds=${this.state.variationIds.join(',')}`,
            {
                method: 'POST',
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            }
        ).then(() => {
            deployEventsTrackerService.trackABbFinished()

            this.setState({
                operationInProcess: false,
                deployComplete: true
            })

            setTimeout(() => {
                this.setState({
                    redirectToAbDetailsInitiated: true
                })
            }, 1500)
        })
        .catch((err) => {
            deployEventsTrackerService.trackAbFailed(err.errorMessage)

            this.setErrorState(err)
        })
    }

    render () {
        if (this.state.errorMessages?.length) {
            return (<ErrorComponent errorMessages={this.state.errorMessages} />)
        } else if (!this.state.tagsNames || !this.state.testVariationData || !this.state.settingsBranchesNamesLoaded || !this.state.variationIds) {
            return 'Gathering information. Please wait ...'
        } else if (this.state.redirectToAbDetailsInitiated) {
            return (
                <Redirect
                    to={`/experimentDescriptionGenerator/${this.props.match.params.siteId}/${this.state.experimentId}/${this.state.variationIds.join(',')}`}
                />
            )
        } else if (this.state.deployComplete) {
            return <span style={{fontSize: 25, backgroundColor: '#009933', color: 'Yellow'}}>Experiment has started!</span>
        } else if (this.state.operationInProcess) {
            return 'Please wait ...'
        } else if (!this.state.logicAndSettingsBranch) {
            return (
                <form>
                    <div>
                        Variations: {this.state.variationIds.join(', ')}
                    </div>
                    <SelectTestVariationLogicBranch handlerLogicBranch={this.handleLogicBranchChoice} testVariationData={this.state.testVariationData} siteId={this.state.siteId} tagsNames={this.state.tagsNames} selectUpdate={this.state.selectVersionLogicBranch} buttonUpdate={this.state.buttonVersionLogicBranch}/>
                    <SelectTestVariationSettingsBranch settingsBranchesNames={this.state.settingsBranchesNames} testVariationId={this.state.variationIds[1]}/>
                    <button type={'button'} onClick={() => this.handleTestVariationChoiceSubmit()} style={{width: '250px', height: '60px', fontSize: 25, marginTop: '20px', cursor: 'pointer'}}>Continue</button>
                </form>
            )
        } else if (!this.state.testVariationDeployed) {
            return <DeployInterface
                operationMode={OPERATION_MODE_DEPLOY_TEST_VARIATION}
                variation={this.state.variationIds[1]}
                siteId={this.props.match.params.siteId}
                settingsBranch={this.state.testVariationSettingsBranch}
                settingsHashBranch={this.state.testVariationSettingsHashBranch}
                logic={this.state.testVariationLogicBranch}
                logicHash={this.state.testVariationLogicHashBranch}
                onDeployComplete={this.handleTestVariationDeployComplete}
            />
        }


        return (

            <form onSubmit={this.handleExperimentFormSubmit}>
                <table cellSpacing={10}>
                    <tbody>
                        <tr>
                            <td>Experiment ID:</td>
                            <td><input onChange={this.handleExperimentIdChange} style={{width: '100%'}} required pattern=".{3,50}"/></td>
                        </tr>
                        <tr>
                            <td>
                                Experiment ID (final):
                            </td>
                            <td>
                                {this.state.experimentId}
                                <input type="hidden" name="experiment_id" value={this.state.experimentId}/>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Variation {this.state.variationIds[0]}:
                            </td>
                            <td>
                                current version used on production
                            </td>
                        </tr>
                        <tr>
                            <td>
                                Settings branch for variation {this.state.variationIds[1]}:
                            </td>
                            <td>
                                Logic: {this.state.testVariationInfo.logic.commitish} / {this.state.testVariationInfo.logic.hash.substring(0, 6)}<br />
                                Settings: {this.state.testVariationInfo.settings.commitish} / {this.state.testVariationInfo.settings.hash.substring(0, 6)}<br />
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={2}>
                                <button type="submit" style={{width: '250px', height: '60px', fontSize: 25}}>Start A/B</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
        )

    }
}

class SelectTestVariationSettingsBranch extends React.Component {
    render() {
        const options = this.props.settingsBranchesNames.map(
            branchName => <option key={branchName}>{branchName}</option>
        )

        return (
            <React.Fragment>
                <p style={{margin: '40px 0 0'}}>Settings branch for variation {this.props.testVariationId}:</p>
                <select id={'settings_branch'} name="settings_branch" style={{width: '70%', fontSize: 20}} required>
                    <option key={'master'}>master</option>
                    {options}
                </select><br />
            </React.Fragment>
        )
    }
}

class SelectTestVariationLogicBranch extends React.Component {
    render() {
        if(this.props.testVariationData) {
            const tagsOptions = this.props.tagsNames.map(
                (branchName, index) => <option key={branchName + index}>{branchName}</option>
            )
            return (
                <React.Fragment>
                    Logic version will be {this.props.testVariationData.version.logic.commitish} / {this.props.testVariationData.version.logic.hash.substring(0, 6)} just as we currently use on production<br />


                    {this.props.buttonUpdate ?
                        <button onClick={() => this.props.handlerLogicBranch()} style={{color: '#ffff99', fontSize: 18, border: 'none', background: 'none', textDecoration: 'underline', cursor: 'pointer'}}>Update to a higher version</button>
                        :
                        null
                    }
                    {this.props.selectUpdate ?
                        <React.Fragment>
                            <p>Logic branch for variation B:</p>
                            <select id={'logic_branch'} name="logic_branch" style={{width: '70%', fontSize: 20}} required>
                                {tagsOptions}
                            </select><br />
                        </React.Fragment>
                        : null
                    }

                </React.Fragment>
            )
        } else {
            return null
        }
    }
}

export default withRouter(StartAb)
