
import React from "react";
import {Link} from "react-router-dom";

import AbShortDescription from './AbShortDescription.js'

const BUILD_STATUSES = {
    EMPTY: 'empty',
    AB: 'ab',
    DEV: 'dev',
    NORMAL: 'normal'
}

export default class BuildInfo extends React.Component {
    render () {

        if (this.props.build.type === BUILD_STATUSES.NORMAL) {
            // logic │ logicHash │      settings │ settingsHash

            return (
                <tr>
                    {this.props.includeSiteId &&
                        <td>
                            {this.props.build.siteId}
                        </td>
                    }
                    {this.props.includeSiteId && typeof this.props.build.domain !== "undefined" &&
                        <td>
                            {this.props.build.domain}
                        </td>
                    }
                    <td style={{textAlign: 'left'}}>
                        {this.props.build.version.logic.commitish}
                    </td>
                    <td>
                        {this.props.build.version.logic.hash.substring(0, 6)}
                    </td>
                    <td>
                        {this.props.build.version.settings.commitish}
                    </td>
                    <td>
                        {this.props.build.version.settings.hash.substring(0, 6)}
                    </td>
                    <td style={{textAlign: 'left'}}>
                        {this.props.build.deployedAt}
                    </td>
                    {this.props.includeActionsBlock &&
                    <td style={{textAlign: 'left'}}>
                        <ul style={{margin: 0}}>
                            {this.props.build.version.settings.commitish !== 'origin/master' ?
                                <li>
                                    <Link to={`/switchToMaster/${this.props.build.siteId}`} style={{color: '#ffff99'}}>Switch
                                        to master</Link>&nbsp;
                                </li> :
                                <li>
                                    <Link to={`/updateSettings/${this.props.build.siteId}`}
                                          style={{color: '#ffff99'}}>Update</Link>&nbsp;
                                </li>
                            }

                            <li>
                                <Link to={`/startAb/${this.props.build.siteId}`} style={{color: '#ffff99'}}>Start
                                    AB</Link>&nbsp;
                            </li>
                            <li>
                                <Link to={`/history/${this.props.build.siteId}`}
                                      style={{color: '#ffff99'}}>History</Link>&nbsp;
                            </li>
                            <li>
                                <Link to={`/demos/${this.props.build.siteId}`} style={{color: '#ffff99'}}>Demos</Link>
                            </li>
                        </ul>
                    </td>
                    }
                </tr>
            )
        } else if (this.props.build.type === BUILD_STATUSES.AB) {
            return <tr>
                {this.props.includeSiteId &&
                <td>
                    {this.props.build.siteId}
                </td>
                }
                {this.props.includeSiteId && typeof this.props.build.domain !== "undefined" &&
                <td>
                    {this.props.build.domain}
                </td>
                }
                <td colSpan={4} style={{textAlign: 'left', backgroundColor: '#800080'}}>Experiment: <AbShortDescription ab={this.props.build.ab}/></td>
                <td style={{textAlign: 'left'}}>
                    {this.props.build.deployedAt}
                </td>
                {this.props.includeActionsBlock &&
                <td style={{textAlign: 'left'}}>
                    <ul style={{margin: 0}}>
                        <li>
                            <Link to={`/updateAb/${this.props.build.siteId}/${this.props.build.ab.id}`}
                                  style={{color: '#ffff99'}}>Variations</Link>&nbsp;
                        </li>
                        <li>
                            <Link to={`/history/${this.props.build.siteId}`} style={{color: '#ffff99'}}>History</Link>
                        </li>
                        <li>
                            <Link to={`/demos/${this.props.build.siteId}`} style={{color: '#ffff99'}}>Demos</Link>
                        </li>
                        <li>
                            <Link style={{color: '#ffff99'}} to={`/experimentDescriptionGenerator/${this.props.build.siteId}/${this.props.build.ab.id}/${Object.keys(this.props.build.ab.variations).join(',')}`}>
                                Experiment template
                            </Link>
                        </li>
                    </ul>
                </td>
                }
            </tr>
        } else if (this.props.build.type === BUILD_STATUSES.DEV) {
            return <tr>
                {this.props.includeSiteId &&
                <td>
                    {this.props.build.siteId}
                </td>
                }
                {this.props.includeSiteId && typeof this.props.build.domain !== "undefined" &&
                <td>
                    {this.props.build.domain}
                </td>
                }
                <td colSpan={4} style={{textAlign: 'left'}}>Custom build</td>
                <td style={{textAlign: 'left'}}>
                    {this.props.build.deployedAt}
                </td>
                {this.props.includeActionsBlock &&
                <td>

                </td>
                }
            </tr>
        } else {
            return <tr>
                {this.props.includeSiteId &&
                <td>
                    {this.props.build.siteId}
                </td>
                }
                {this.props.includeSiteId && typeof this.props.build.domain !== "undefined" &&
                <td>
                    {this.props.build.domain}
                </td>
                }
                <td colSpan={4}>UNKNOWN: {this.props.build.type}</td>
                <td>
                    {this.props.build.deployedAt}
                </td>
                {this.props.includeActionsBlock &&
                <td>

                </td>
                }
            </tr>
        }

    }
}
