import React from 'react';
class ErrorComponent extends React.Component {

    constructor(props) {
        super(props);

        this.state = {errorMessages: this.props.errorMessages || [] }
    }

    componentDidUpdate(nextProps) {
        if (nextProps.errorMessages !== this.state.errorMessages) {
            this.setState({errorMessages: nextProps.errorMessages})
        }
    }
    render () {
        return(<div style={{position: "absolute", width: "100vw", display: 'flex',justifyContent: "center", alignItems: "center", flexDirection: 'column'}}>
            {this.state.errorMessages.map(el => {
                return (<h2 style={{color: 'red'}}>{el.errorMessage}</h2>)
            })}
        </div>)
    }
}

export default (ErrorComponent)