import React from 'react';

import {withRouter, Redirect} from "react-router-dom";

const BACKEND_DOMAIN = document.location.hostname === 'localhost' ? 'http://localhost:5000' : ''

class Rollout extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loadingData: true,
            redirectToListSites: false,
            errorOccurred: false
        }

        this.rolloutVariation = this.rolloutVariation.bind(this);
    }

    componentDidMount() {
        this.rolloutVariation()
    }

    rolloutVariation() {
        this.setState({
            loadingData: true
        })

        fetch(
            BACKEND_DOMAIN + `/api/rolloutVariation?siteId=${this.props.match.params.siteId}&variation=${this.props.match.params.variation}`,
            {
                method: 'POST',
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            }
        ).then(
            (result) => {
                if (result.ok) {
                    result.json().then(data => {
                        console.log(data)

                        this.setState({
                            loadingData: false,
                            redirectToListSites: true
                        })
                    })
                } else {
                    result.text().then(text => {
                        console.log(text)
                    })

                    this.setState({
                        errorOccurred: true
                    })
                }
            },
            (error) => {
                console.log(error)

                this.setState({
                    errorOccurred: true
                })
            }
        )
    }


    render () {
        if (this.state.errorOccurred) {
            return (
                <div style={{textAlign: "center"}}>
                    <span style={{fontSize: 25, backgroundColor: '#cc0000', color: '#ffccff'}}>Error occurred</span>
                </div>
            )
        } else if (this.state.loadingData) {
            return (
                <div style={{textAlign: "center"}}>
                    Loading. Please wait...
                </div>
            )
        } else if(this.state.redirectToListSites){
            return (
                <Redirect
                    to={`/`}
                />
            )
        }
    }
}

export default withRouter(Rollout)
