
import React from 'react';

import {withRouter} from "react-router-dom";

const BACKEND_DOMAIN = document.location.hostname === 'localhost' ? 'http://localhost:5000' : ''

class Demos extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loadingData: true,
            errorOccurred: false,
            demosInfo: null,

            siteId: null,
            showCreateDemoButton: true,
            showCreateDemoForm: false,
            demoLogicBranch: null,
            demoSettingsBranch: null,
            newDemoName: null,
            settingsBranchesNames: null,
            logicBranchesNames: null,
            tagsNames: null
        }

        this.deleteDemo = this.deleteDemo.bind(this)
        this.createOrUpdateDemo = this.createOrUpdateDemo.bind(this)
        this.showCreateDemoForm = this.showCreateDemoForm.bind(this)
        this.handleDemoForm = this.handleDemoForm.bind(this)

    }

    componentDidMount() {
        this.demosSitesInfo()

        this.loadSettingsBranchesNames()
        this.loadLogicBranchesNames()
        this.loadTagsNames()
    }

    loadSettingsBranchesNames() {
        fetch(
            BACKEND_DOMAIN + '/api/settingsBranchNames',
            {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            }
        ).then(
            (result) => {
                if (result.ok) {
                    result.json().then(data => {
                        console.log(data)

                        this.setState({
                            settingsBranchesNames: data
                        })
                    })
                } else {
                    result.text().then(text => {
                        console.log(text)
                    })

                    this.setState({
                        errorOccurred: true
                    })
                }
            },
            (error) => {
                console.log(error)

                this.setState({
                    errorOccurred: true
                })
            }
        )
    }

    loadLogicBranchesNames() {
        fetch(
            BACKEND_DOMAIN + '/api/logicBranchNames',
            {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            }
        ).then(
            (result) => {
                if (result.ok) {
                    result.json().then(data => {
                        console.log(data)

                        this.setState({
                            logicBranchesNames: data
                        })
                    })
                } else {
                    result.text().then(text => {
                        console.log(text)
                    })

                    this.setState({
                        errorOccurred: true
                    })
                }
            },
            (error) => {
                console.log(error)

                this.setState({
                    errorOccurred: true
                })
            }
        )
    }

    loadTagsNames() {
        fetch(
            BACKEND_DOMAIN + '/api/tagsNames',
            {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            }
        ).then(
            (result) => {
                if (result.ok) {
                    result.json().then(data => {
                        console.log(data)

                        this.setState({
                            tagsNames: data
                        })
                    })
                } else {
                    result.text().then(text => {
                        console.log(text)
                    })

                    this.setState({
                        errorOccurred: true
                    })
                }
            },
            (error) => {
                console.log(error)

                this.setState({
                    errorOccurred: true
                })
            }
        )

    }

    demosSitesInfo() {
        this.setState({
            loadingData: true,
            showCreateDemoButton: false
        })

        let url = BACKEND_DOMAIN + `/api/demosStatuses?siteId=${this.props.match.params.siteId}`
        fetch(
            url,
            {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            }
        ).then(
            (result) => {
                if (result.ok) {
                    result.json().then(data => {

                        this.setState({
                            loadingData: false,
                            demosInfo: data,
                            siteId: this.props.match.params.siteId,
                            showCreateDemoButton: true
                        })
                    })
                } else {
                    result.text().then(text => {
                        console.log(text)
                    })

                    this.setState({
                        errorOccurred: true
                    })
                }
            },
            (error) => {
                console.log(error)

                this.setState({
                    errorOccurred: true
                })
            }
        )
    }

    deleteDemo(demoId, siteId) {

        this.setState({
            loadingData: true,
            showCreateDemoButton: false
        })

        if(demoId !== undefined) {

            let url = BACKEND_DOMAIN + `/api/deleteDemo?demoId=${demoId}&siteId=${siteId}`
            fetch(
                url,
                {
                    headers: {
                        Authorization: 'Bearer ' + localStorage.getItem('token')
                    }
                }
            ).then(
                (result) => {

                    if (result.ok) {
                        this.demosSitesInfo()
                        this.setState({
                            loadingData: false,
                            showCreateDemoButton: true
                        })
                    } else {
                        result.text().then(text => {
                            console.log(text)
                        })
                    }
                },
                (error) => {
                    console.log(error)
                }
            )
        }
    }


    createOrUpdateDemo(siteId, demoId, logicCommitish, settingsBranch) {

        this.setState({
            loadingData: true,
            showCreateDemoButton: false
        })

        let url = BACKEND_DOMAIN + `/api/createOrUpdateDemo?siteId=${siteId}&demoId=${demoId}&logicCommitish=${logicCommitish}&settingsBranch=${settingsBranch}`
        fetch(
            url,
            {
                headers: {
                    Authorization: 'Bearer ' + localStorage.getItem('token')
                }
            }
        ).then(
            (result) => {
                if (result.ok) {
                    this.demosSitesInfo()
                    this.setState({
                        loadingData: false,
                        showCreateDemoButton: true
                    })
                } else {
                    result.text().then(text => {
                        console.log(text)
                    })
                }
            },
            (error) => {
                console.log(error)
            }
        )
    }

    showCreateDemoForm() {
        this.setState({
            showCreateDemoButton: false,
            showCreateDemoForm: true
        })
    }

    handleDemoForm(e) {

        e.preventDefault()

        let newDemoName = document.getElementById('new_demo_name').value
        let demoSettingsBranch = document.getElementById('settings_branch').value
        let demoLogicBranch = document.getElementById('logic_branch').value

        this.setState({
            loadingData: true,
            showCreateDemoButton: false,
            showCreateDemoForm: false,
        })

        this.createOrUpdateDemo(this.state.siteId, newDemoName, demoLogicBranch, demoSettingsBranch)
    }


    render () {
        if (this.state.loadingData) {
            return (
                <div style={{textAlign: "center"}}>
                    Loading. Please wait...
                </div>
            )
        } else if(this.state.demosInfo[0]) {

            const demosInfoRows = this.state.demosInfo.map((demoInfo, index) => <BuildDemoInfo createOrUpdateDemo={this.createOrUpdateDemo} siteId={this.state.siteId} deleteDemo={this.deleteDemo} build={demoInfo} key={index} />)

            return (
                <React.Fragment>
                    <p>Demos {this.state.siteId}</p>
                    <table border={1} width={'85%'} style={{fontSize: 19}}>
                        <thead>
                        <tr>
                            <td rowSpan={2}>ID</td>
                            <td colSpan={4}>Build info</td>
                            <td rowSpan={2}>Deployed At</td>
                            <td rowSpan={2}>Controls</td>
                        </tr>
                        <tr>
                            <td>Logic</td>
                            <td>Logic Hash</td>
                            <td>Settings</td>
                            <td>Settings Hash</td>
                        </tr>
                        </thead>
                        <tbody>
                        {demosInfoRows}
                        </tbody>
                    </table>

                    {this.state.showCreateDemoButton ? <button onClick={() => this.showCreateDemoForm()} style={{color: '#000000', width: '250px', marginTop: 50, height: '60px', fontSize: 25, cursor: 'pointer'}}>Create new demo</button> : null}
                    {this.state.showCreateDemoForm ?
                        <div style={{marginTop: 50}}>
                            <p>Create Demo</p>
                            <form onSubmit={this.handleDemoForm}>
                                <SelectLogicBranch siteId={this.state.siteId} tagsNames={this.state.tagsNames} branchesNames={this.state.logicBranchesNames}/>
                                <SelectSettingsBranch siteId={this.state.siteId} branchesNames={this.state.settingsBranchesNames}/>
                                <InputDemoName  />
                                <button onClick={() => this.handleDemoForm} style={{width: '250px', height: '60px', fontSize: 25, marginTop: '20px', cursor: 'pointer'}}>Create demo</button>

                            </form>
                        </div> : null
                    }
                </React.Fragment>

            )
        } else if(this.state.showCreateDemoButton) {
            return <button onClick={() => this.showCreateDemoForm()} style={{color: '#000000', width: '250px', marginTop: 50, height: '60px', fontSize: 25, cursor: 'pointer'}}>Create new demo</button>
        } else if(this.state.showCreateDemoForm) {
            return (
                <React.Fragment>
                    <p>Create Demo</p>
                    <form onSubmit={this.handleDemoForm}>
                        {this.state.logicBranchesNames ?
                            <SelectLogicBranch siteId={this.state.siteId} tagsNames={this.state.tagsNames} branchesNames={this.state.logicBranchesNames}/>
                        : null
                        }
                        {this.state.settingsBranchesNames ?
                            <SelectSettingsBranch siteId={this.state.siteId} branchesNames={this.state.settingsBranchesNames}/>
                        : null
                        }
                        <InputDemoName  />
                        <button onClick={() => this.handleDemoForm} style={{width: '250px', height: '60px', fontSize: 25, marginTop: '20px', cursor: 'pointer'}}>Create demo</button>
                    </form>
                </React.Fragment>
            )
        } else {
            return (
                <div style={{textAlign: "center"}}>
                    You don't have demos for this site
                </div>
            )
        }
    }
}

class BuildDemoInfo extends React.Component {

    render () {
        if(this.props.build) {
            return (
                <tr>
                    <td>
                        {this.props.build.demoId}
                    </td>
                    <td>
                        {this.props.build.logic}
                    </td>
                    <td>
                        {this.props.build.logicHash}
                    </td>
                    <td>
                        {this.props.build.settings}
                    </td>
                    <td>
                        {this.props.build.settingsHash}
                    </td>
                    <td>
                        {this.props.build.deployedAt}
                    </td>
                    <td>

                        <button onClick={() => this.props.deleteDemo(this.props.build.demoId, this.props.siteId)} style={{color: '#ffff99', fontSize: 18, border: 'none', background: 'none', textDecoration: 'underline', cursor: 'pointer'}}>Delete</button>
                        <button onClick={() => this.props.createOrUpdateDemo(this.props.siteId, this.props.build.demoId, this.props.build.logic, this.props.build.settings)} style={{color: '#ffff99', fontSize: 18, border: 'none', cursor: 'pointer', background: 'none', textDecoration: 'underline'}}>Update</button>

                    </td>
                </tr>
            )
        }
    }
}


class SelectLogicBranch extends React.Component {
    render() {
        const options = this.props.branchesNames.map(
            (branchName, index) => <option key={branchName + index}>
                origin/{branchName}
            </option>
        )
        const tagsOptions = this.props.tagsNames.map(
            (branchName, index) => <option key={branchName + index}>{branchName}</option>
        )

        return (
            <div>
                Choose logic branch for site {this.props.siteId}<br />
                <select id={'logic_branch'} name="logic_branch" style={{width: '70%', fontSize: 20}} required>
                    {tagsOptions}
                    {options}
                </select><br />
            </div>
        )
    }
}

class SelectSettingsBranch extends React.Component {
    render() {
        const options = this.props.branchesNames.map(
            (branchName, index) => <option key={branchName + index}>
                origin/{branchName}
            </option>
        )

        return (
            <div style={{marginTop: 35}}>
                Choose settings branch for site {this.props.siteId}<br />
                <select id={'settings_branch'} name="settings_branch" style={{width: '70%', fontSize: 20}} required>
                    {options}
                </select><br />
            </div>
        )
    }
}

class InputDemoName extends React.Component {
    render() {
        return (
            <div style={{marginTop: 35}}>
                Choose demo ID for site {this.props.siteId}<br />
                <input type={'text'} pattern={'[a-zA-Z0-9_-]{3,35}'} id="new_demo_name" name="new_demo_name" style={{width: '70%', fontSize: 20}} title="Demo name should only contain english letters, numbers and next symbols: '_' '-'" required />
                <br />
            </div>
        )
    }
}


export default withRouter(Demos)
