
import React from 'react';

import {withRouter} from "react-router-dom";

import BuildInfo from './BuildInfo.js'

import {queryApiGet, UnauthorizedQueryError, RequestProcessingError} from '../api.js'
import ErrorComponent from "./error";

class DeploysHistory extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loadingData: true,
            historyData: null
        }

        this.processError = this.processError.bind(this)
    }

    componentDidMount() {
        if (!this.props.useStub) {
            this.loadHistoryData()
        }
    }

    processError(e) {
        if (e instanceof UnauthorizedQueryError) {
            console.error('Unauthorized')
        } else if (e instanceof RequestProcessingError) {
            console.error(e.message)

            this.setState({
                errorOccurred: true
            })
        } else {
            throw e
        }
    }

    loadHistoryData() {
        let url = `/api/history?siteId=${this.props.match.params.siteId}`
        if (this.props.match.params.variation) {
            url = url + '&variation=' + this.props.match.params.variation
        }

        queryApiGet(
            url
        ).then(data => {
            this.setState({
                loadingData: false,
                historyData: data
            })
        }).catch(er => this.setErrorState(er))
    }

    setErrorState(errorMessage) {
        const res = [...this.state.errorMessages || [], errorMessage]

        this.setState({errorMessages: res})
    }

    render () {
        if (this.state.errorMessages?.length) {
            return (<ErrorComponent errorMessages={this.state.errorMessages}></ErrorComponent>)
        }

        if (this.state.loadingData) {
            return (
                <div style={{textAlign: "center"}}>
                    Loading. Please wait...
                </div>
            )
        }

        const siteInfoRows = this.state.historyData.map(siteInfo => <BuildInfo build={siteInfo} key={siteInfo.url} />)

        return (
            <table border={1} width={'85%'} style={{fontSize: 19}}>
                <thead>
                <tr>
                    <td colSpan={4}>Build info</td>
                    <td rowSpan={2}>Deployed At</td>
                </tr>
                <tr>
                    <td>Logic</td>
                    <td>Logic Hash</td>
                    <td>Settings</td>
                    <td>Settings Hash</td>
                </tr>
                </thead>
                <tbody>
                {siteInfoRows}
                </tbody>
            </table>
        )
    }
}

export default withRouter(DeploysHistory)
