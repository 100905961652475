
import React from 'react';
import {Link, withRouter} from "react-router-dom";

import AbShortDescription from './AbShortDescription.js'

import {queryApiGet} from '../api.js'
import ErrorComponent from "./error";

class VariationsInfo extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loadingData: true,
            abInfo: null,
            errorOccurred: false
        };
    }

    componentDidMount() {
        this.loadAbInfo()
    }

    setErrorState(errorMessage) {
        this.setState({errorMessages: [...this.state.errorMessages || [], errorMessage]})
    }

    loadAbInfo() {
        queryApiGet(`/api/abInfo?siteId=${this.props.match.params.siteId}`)
        .then(data => {
            this.setState({
                loadingData: false,
                currentAbData: data
            })
        }).catch((e) => this.setErrorState(e))
    }

    render() {
        if (this.state.errorMessages?.length) {
            return (<ErrorComponent errorMessages={this.state.errorMessages} />)
        } else if (this.state.loadingData) {
            return 'Loading a/b data, please wait...'
        }

        const variationInfoRows = this.state.currentAbData.variationsBuildsInfos.map(build => {
            return (
                <VariationBuildInfo key={build.variation} build={build}/>
            )
        })

        return (
            <div>
                <div>
                    Current AB: <AbShortDescription ab={this.state.currentAbData.abInfo}/>
                </div>
                <table border={1} style={{marginTop: '15px'}}>
                    <thead>
                        <tr>
                            <td>
                                Variation
                            </td>
                            <td>
                                Logic
                            </td>
                            <td>
                                Logic Hash
                            </td>
                            <td>
                                Settings
                            </td>
                            <td>
                                Settings Hash
                            </td>
                            <td>
                                Last Deploy At
                            </td>
                            <td>
                                Controls
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                    {variationInfoRows}
                    </tbody>
                </table>
            </div>
        )
    }
}


class VariationBuildInfo extends React.Component {
    render () {
        if (this.props.build.type === "dev") {
            return (
                <tr>
                    <td>
                        {this.props.build.variation}
                    </td>
                    <td colSpan={4} style={{textAlign: 'left', color: 'red'}}>
                        DEV BUILD
                    </td>
                    <td style={{textAlign: 'left'}}>
                        {this.props.build.lastModified}
                    </td>
                    <td>
                        --
                    </td>
                </tr>
            )
        }

        return (
            <tr>
                <td>
                    {this.props.build.variation}
                </td>
                <td style={{textAlign: 'left'}}>
                    {this.props.build.version.logic.commitish}
                </td>
                <td>
                    {this.props.build.version.logic.hash.substring(0, 6)}
                </td>
                <td>
                    {this.props.build.version.settings.commitish}
                </td>
                <td>
                    {this.props.build.version.settings.hash.substring(0, 6)}
                </td>
                <td style={{textAlign: 'left'}}>
                    {this.props.build.lastModified}
                </td>
                <td style={{textAlign: 'left'}}>
                    <ul>
                        <li>
                            <Link to={`/updateSettings/${this.props.build.siteId}/${this.props.build.variation}`} style={{color: '#ffff99'}}>Update</Link>
                        </li>
                        <li>
                            <Link to={`/history/${this.props.build.siteId}/${this.props.build.variation}`} style={{color: '#ffff99'}}>History</Link>
                        </li>
                        <li>
                            <Link to={`/rolloutVariation/${this.props.build.siteId}/${this.props.build.variation}`}  style={{color: '#ffff99'}}>Rollout to 100%</Link>
                        </li>
                    </ul>
                </td>
            </tr>
        )
    }
}

export default withRouter(VariationsInfo)
